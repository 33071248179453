import MakesChart from "./../Charts/CarMakes";

export default {
    name: 'CrossSellMapAsideDetails',

    components: {
        MakesChart,
    },

    props: {
        category: {
            type: String,
            enum: ['dealers', 'sellers', 'makes', 'lenders', 'buyers']
        },
        mapBoundary: Object,
        datePicker: Object,
    },

    data() {
        return {
            headerTxt: 'details',
            makes: {
                name: 'makes',
                selected: '',
                data: ['Toyota', 'Chevrolet'],
                visible: false,
            },
            models: {
                name: 'models',
                selected: '',
                data: ['Corolla', 'Trax'],
                visible: false,
            },
            years: {
                name: 'years',
                selected: '',
                data: ['2021', '2020'],
                visible: false,
            },
        }
    },

    computed: {
        filters() {
            if (this.category === '') {
                return [this.years, this.makes, this.models];
            } else return [];
        }
    },

    mounted() {
    },

    methods: {
        close() {
            this.$emit('close');
        },

        toggleDropdown(filter) {
            const visible = filter.visible;

            this.makes.visible = false;
            this.models.visible = false;
            this.years.visible = false;

            filter.visible = !visible;
        },

        filterActive(filter) {
            return filter.visible || filter.selected.length;
        },

        filterSelected(filter) {
            return filter.selected.length ? filter.selected : this.$t(filter.name);
        }
    },
}