<template>
  <div class="drawing-tools">
    <div class="drawing-tools__wrapper">
      <div class="drawing-tools__modes">
          <i v-for="shape in shapeTypes" :key="shape"
             :class="{
              'ez-icon-format-16-9': shape === 'rectangle',
              'ez-icon-circle-outlined': shape === 'circle',
              'ez-icon-chart-line-up': shape === 'polyline',
              'ez-icon-free-transform': shape === 'polygon',
              'selected': shapeSelected === shape
            }"
             @click="changeDrawingMode(shape)"/>
      </div>
      <div class="drawing-tools__actions">
        <div class="cancel" @click="deleteSelection">
          <i class="ez-icon-clear"/>
        </div>
        <div class="apply" @click="saveSelection">
          <i class="ez-icon-done"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DrawingToolbar',

  data() {
    return {
      shapeTypes: ['rectangle', 'circle', 'polyline', 'polygon'],
      shapeSelected: ''
    }
  },

  methods: {
    changeDrawingMode(mode) {
      this.$emit('drawingmode_changed', mode);
      this.shapeSelected = mode;
    },

    deleteSelection() {
      this.$emit('delete_selection');
    },

    saveSelection() {
      this.$emit('save_selection');
    }
  }
}
</script>

<style scoped lang="scss">
.drawing-tools {
  position: absolute;
  top: 20px;
  left: 0;
  height: 48px;
  width: 420px;
  z-index: 2;
  padding: 0 10px;

  &__wrapper {
    background: white;
    height: 100%;
    border-radius: 3px;
    display: grid;
    grid-template-columns: 1fr 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    position: relative;
  }

  &__modes {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 0 20px;

    i {
      cursor: pointer;
      color: color(bg-icon);
      font-size: 1.4rem;
      padding: 8px;
      border-radius: 50%;
      transition: ease-in-out .2s;

      &.selected,
      &:hover {
        background: color(border);
        color: color(bg-black2);
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
    position: relative;
    border-radius: 0 3px 3px 0;
    overflow: hidden;

    &:after {
      background: color(border);
      position: absolute;
      content: '';
      width: 1px;
      left: 0;
      bottom: 7px;
      top: 7px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease-in-out .2s;

      &:hover {
        background: color(bg-fill);
      }

      i {
        color: color(bg-primary);
        font-size: 1.4rem;
      }
    }
  }
}
</style>

<i18n scoped>
{
  "en": {
  },
  "es": {
  }
}
</i18n>