import mapHelpers from './helpers';
import Utils from '@/helpers/utils';
import PlaceAutoComplete from '@/helpers/maps/placeAutoComplete';
import PlaceSearch from '@/helpers/maps/placeSearch';
import Geojson from '@/helpers/maps/geojson';

import DrawingToolbar from './DrawingToolbar';
import MapAside from './../MapAside';

import CrossSell from '@/services/cross.sell.service';

const service = new CrossSell();

export default {
    name: 'CrossSellMap',

    components: {
        DrawingToolbar,
        MapAside
    },

    data() {
        return {
            searchTerm: '',
            showAutocomplete: false,
            autocompleteType: ['(regions)'],
            places: [],

            map: null,
            mapZoom: 8,
            mapMode: null,
            mapTypeId: 'roadmap',
            mapCenter: {},
            mapViewportPolygon: null,
            mapOptions: mapHelpers.mapOptions(),
            mapLocation: {
                type: '',
                value: '',
            },
            myCoordinates: {
                lat: 0.0,
                lng: 0.0
            },

            totalCount: -1,
            shapes: [],
            polygons: [],
            markers: [],

            markerIcon: mapHelpers.markerIcon().default,
            markerIconHover: mapHelpers.markerIcon().hover,

            shapeOptions: mapHelpers.shapeOptions().raw,
            appliedShapeOptions: mapHelpers.shapeOptions().applied,
            polygonGeojson: {},

            infoWinIdx: -1,
            infoWindowPos: null,
            infoWinOpen: false,
            infoWinOpts: mapHelpers.infoWindowsOptions(),
            showMapTypeControl: false,

            isLoading: false,
        }
    },

    computed: {
        mapCoordinates() {
            if (!this.map) {
                return {
                    lat: 0.0,
                    lng: 0.0
                }
            }

            return {
                lat: this.map.getCenter().lat(),
                lng: this.map.getCenter().lng()
            }
        },

        showMapInfo() {
            return this.mapMode !== 'edit' && this.markers.length;
        },

        autocompletePh() {
            return this.autocompleteType.indexOf('(regions)') > -1
                ? this.$t('search_term_default')
                : this.$t('search_term_establishment')
        },

        showAside() {
            return this.polygons.length > 0 && this.totalCount > 0;
        }
    },

    async created() {
        if (localStorage.cross_sell_center) {
            const mapCenter = JSON.parse(localStorage.cross_sell_center);

            mapCenter.lat = parseFloat(mapCenter.lat);
            mapCenter.lng = parseFloat(mapCenter.lng);

            this.myCoordinates = mapCenter;
        } else {
            this.myCoordinates = await this.$getLocation();
        }

        if (localStorage.cross_sell_zoom) {
            this.mapZoom = parseInt(localStorage.cross_sell_zoom);
        }
    },

    async mounted() {
        this.map = await this.$refs.mapRef.$mapPromise;
        this.map.set('styles', mapHelpers.mapStyles());

        setTimeout(() => {
            this._setMapViewportPolygon();
        }, 500);
    },

    methods: {
        async getLocations() {
            this.isLoading = true;

            const res = await service.locations(this.mapLocation.type, this.mapViewportPolygon, this.mapLocation.value);
            if (res && !res.error) {
                this.totalCount = res.data.count;
                this.isLoading = false;

                this.markers = res.data.locations.map(dealer => {
                    return {
                        position: {
                            lat: dealer.Seller_Location.coordinates[1],
                            lng: dealer.Seller_Location.coordinates[0],
                        },
                        icon: this.markerIcon,
                        dealer: dealer,
                    }
                });
            }
        },

        //region Autocomplete
        searchLocation(input) {
            const autocomplete = new PlaceAutoComplete({types: this.autocompleteType});

            autocomplete.getPlacePredictions(input, (predictions, status) => {
                if (status === 'OK') {
                    this.places = autocomplete.defaultTypes.indexOf('establishment') > -1
                        ? predictions.filter(e => e.types.indexOf('car_dealer') > -1)
                        : predictions;

                } else {
                    this.places = [];
                }
            });
        },

        toggleAutocompleteType() {
            this.autocompleteType = this.autocompleteType.indexOf('(regions)') > -1
                ? ['establishment']
                : ['(regions)'];

            this.searchTerm = '';
            this.showAutocomplete = false;
            this.places = [];
        },

        geoCodeLocation(location) {
            this.searchTerm = location.description;
            this.showAutocomplete = false;
            this.getLocationType(location);

            if (this.mapLocation.type === 'postal_code') {

                const zipCode = this.mapLocation.value;
                service.getZipcodeGeojson(zipCode)
                    .then(res => {
                        if (res.data) {
                            this.polygonGeojson = res.data.geojson;
                            this.mapCenter.latLang = res.data.latLang;
                        }
                    })

            } else if (this.mapLocation.type === 'establishment') {

                const place = new PlaceSearch({});
                place.TextSearch(this.mapLocation.value, (results, status) => {
                    if (status === 'OK') {
                        if (results.length === 1) {
                            const polygon = mapHelpers.generatePolygonFromSquare(results[0].geometry.viewport);
                            this.mapLocation.value = polygon;
                            this.polygons = [polygon];
                            this._zoomAndCenterPolygon('Polygon');
                        }
                    }
                });

            } else {

                const geojson = new Geojson();
                geojson.getFromOpenStreetMap(this.mapLocation.value)
                    .then(res => {
                        if (res) {
                            this.polygonGeojson = res.geojson;
                            this.mapCenter.boundingBox = res.boundingBox;
                            this.mapCenter.latLang = res.latLang;
                        }
                    })

            }
        },

        getLocationType(location) {
            const result = {type: '', value: ''};

            if (location.types.findIndex(type => type === 'establishment') > -1) {
                result.type = 'establishment';
                result.value = location.terms[0].value;
            } else if (location.types.findIndex(type => type === 'postal_code') > -1) {
                result.type = 'postal_code';
                result.value = location.terms[2].value;
            } else if (location.types.findIndex(type => type === 'administrative_area_level_1') > -1) {
                result.type = 'location';
                result.value = location.description;
            } else if (location.types.findIndex(type => type === 'locality') > -1) {
                result.type = 'location';
                result.value = location.description;
            } else if (location.types.findIndex(type => type === 'country') > -1) {
                result.type = 'location';
                result.value = location.description;
            }

            this.mapLocation = result;
        },
        //endregion

        //region Map events
        onMapClick() {
            this.onMarkerInfoClose();
            this.showMapTypeControl = false;
        },

        onMapDragOrZoom() {
            let mapCenter = this.mapCoordinates,
                mapZoom = this.map.getZoom();

            localStorage.cross_sell_center = JSON.stringify(mapCenter);
            localStorage.cross_sell_zoom = JSON.stringify(mapZoom);

            this._setMapViewportPolygon();
        },

        onMarkerMouseOver(marker, idx) {
            if (this.markers[this.infoWinIdx]) {
                this.markers[this.infoWinIdx].icon = this.markerIcon;
            }
            this.markers[idx].icon = this.markerIconHover;
            this.toggleInfoWindow(marker, idx);
        },

        onMarkerMouseOut(marker, idx) {
            if (this.infoWinIdx !== idx) {
                this.markers[idx].icon = this.markerIcon;
            }
        },

        onMarkerClick(marker, idx) {
            this.$emit('marker_click', marker);
            this.markers[idx].icon = this.markerIconHover;
        },

        onMarkerInfoClose() {
            this.infoWinOpen = false;
            if (this.markers[this.infoWinIdx]) {
                this.markers[this.infoWinIdx].icon = this.markerIcon;
            }
        },

        onDrawingChange($event, f) {
            f.setDrawingMode($event);
            this.shapes.forEach(shape => shape.overlay.setMap(null));
            this.mapOptions.draggableCursor = 'default';
            this.mapOptions.draggable = false;
        },

        onDrawingClose(f) {
            f.deleteSelection();
            this.mapOptions.draggableCursor = null;
            this.mapOptions.draggable = true;
            this.mapMode = null;
        },

        onDrawingSave(f) {
            this.mapOptions.draggableCursor = null;
            this.mapOptions.draggable = true;
            this.mapMode = null;

            if (this.shapes.length) {
                this._onMapOverlayCompleted();
                f.deleteSelection();
            }
        },

        onRemoveBoundary() {
            this.$emit('remove_boundary');
            this.mapOptions.draggableCursor = null;
            this.mapOptions.draggable = true;
            this.mapLocation.type = '';
            this.mapLocation.value = '';
            this.searchTerm = '';
            this.mapMode = null;
            this.polygons = [];
            this.shapes = [];

            setTimeout(() => {
                this._setMapViewportPolygon();
            }, 500);
        },

        mapZoomIn() {
            this.map.setZoom(this.map.getZoom() + 1);
        },

        mapZoomOut() {
            this.map.setZoom(this.map.getZoom() - 1);
        },
        //endregion

        //region Map utils
        _onMapOverlayCompleted() {
            try {
                const [lastShape] = this.shapes.slice(-1),
                    overlay = lastShape.overlay;

                let polygon = [];

                switch (lastShape.type) {
                    case 'circle':
                        polygon = mapHelpers.generatePolygonFromCircle(overlay.getCenter(), overlay.getRadius(), 50);
                        break;
                    case 'rectangle':
                        polygon = mapHelpers.generatePolygonFromSquare(overlay.getBounds());
                        break;
                    default:
                        polygon = overlay.getPath().getArray().map(latlng => {
                            return {
                                lat: latlng.lat(),
                                lng: latlng.lng()
                            }
                        });
                }

                this.mapLocation.type = 'shape';
                this.mapLocation.value = polygon;
                this.polygons.push(polygon);

                this._zoomAndCenterPolygon('Polygon');

            } catch (e) {
                console.error(e);
            }
        },

        _setMapViewportPolygon() {
            this.mapViewportPolygon = mapHelpers.generatePolygonFromSquare(this.map.getBounds());

            if (this.polygons.length <= 0) {
                this.mapLocation.type = 'viewport';
                this.mapLocation.value = null;
            }

            this.getLocations();
        },

        _zoomAndCenterPolygon(type) {
            const polygon = type === 'MultiPolygon'
                ? Utils.LongestArrayOfMatrix(this.polygons)
                : this.polygons[0];

            const latLngBounds = new window.google.maps.LatLngBounds();
            for (let latLang of polygon) {
                latLngBounds.extend(latLang || latLang[0]);
            }

            this.map.fitBounds(latLngBounds, {left: 420});
        },
        //endregion

        //region Map modals
        toggleInfoWindow(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoWinOpts.content = marker.dealer.Seller;
            this.infoWinOpen = true;
            this.infoWinIdx = idx;
        },

        toggleMapTypeControl(mapType) {
            this.showMapTypeControl = !this.showMapTypeControl;
            if (typeof mapType === 'string') {
                this.mapTypeId = mapType;
            }
        },
        //endregion
    },

    watch: {
        searchTerm(val) {
            if (val.length > 0) {
                this.searchLocation(val);
                this.showAutocomplete = this.places.length
                    ? !(this.places.findIndex(pl => pl.description === val) > -1)
                    : false;

            } else {
                this.showAutocomplete = false;
            }
        },

        polygonGeojson(val) {
            const geojson = mapHelpers.generatePolygonFromGeojson(val);

            if (geojson.polygons.length) {
                this.mapOptions.draggableCursor = null;
                this.mapOptions.draggable = true;
                this.polygons = geojson.polygons;
                this._zoomAndCenterPolygon(geojson.geometry);
            }
        }
    }
}