var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawing-tools"},[_c('div',{staticClass:"drawing-tools__wrapper"},[_c('div',{staticClass:"drawing-tools__modes"},_vm._l((_vm.shapeTypes),function(shape){return _c('i',{key:shape,class:{
            'ez-icon-format-16-9': shape === 'rectangle',
            'ez-icon-circle-outlined': shape === 'circle',
            'ez-icon-chart-line-up': shape === 'polyline',
            'ez-icon-free-transform': shape === 'polygon',
            'selected': _vm.shapeSelected === shape
          },on:{"click":function($event){return _vm.changeDrawingMode(shape)}}})}),0),_c('div',{staticClass:"drawing-tools__actions"},[_c('div',{staticClass:"cancel",on:{"click":_vm.deleteSelection}},[_c('i',{staticClass:"ez-icon-clear"})]),_c('div',{staticClass:"apply",on:{"click":_vm.saveSelection}},[_c('i',{staticClass:"ez-icon-done"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }