class PlaceAutoComplete {

    constructor(options) {
        this._defaultTypes = options.types || ['(regions)'];
        this._defaultRestrictions = options.restrictions || {country: 'us'};
    }

    get defaultTypes() { return this._defaultTypes }
    get defaultRestrictions() { return this._defaultRestrictions }

    set defaultTypes(types) { this._defaultTypes = types; }
    set defaultRestrictions(restrictions) { this._defaultRestrictions = restrictions; }

    createRequestService() {
        return new window.google.maps.places.AutocompleteService();
    }

    getPlacePredictions(query, callback = null) {

        this.createRequestService().getPlacePredictions({
            input: query,
            types: this.defaultTypes,
            componentRestrictions: this.defaultRestrictions,
        }, callback);
    }

    getQueryPredictions(query, callback = null) {
        this.createRequestService().getPlacePredictions(query, callback);
    }
}

export default PlaceAutoComplete;