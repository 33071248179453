export default {
    mapStyles() {
        return [
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    },

    mapOptions() {
        return {
            disableDefaultUI: true,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            draggableCursor: null,
            zoomControl: false,
            draggable: true,
            //maxZoom: 12,
            minZoom: 5,
        }
    },

    generatePolygonFromSquare(bounds) {
        try {
            const coordinates = {
                north: bounds.getNorthEast().lat(),
                east: bounds.getNorthEast().lng(),
                south: bounds.getSouthWest().lat(),
                west: bounds.getSouthWest().lng()
            };

            return [
                {lat: coordinates.north, lng: coordinates.west}, // north west
                {lat: coordinates.south, lng: coordinates.west}, // south west
                {lat: coordinates.south, lng: coordinates.east}, // south east
                {lat: coordinates.north, lng: coordinates.east}, // north east
            ];

        } catch (e) {
            return [];
        }
    },

    generatePolygonFromCircle(center, radius, numSides) {
        try {
            const polygon = [],
                degreeStep = 360 / numSides;

            for (let i = 0; i < numSides; i++) {
                const gpos = window.google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
                polygon.push({lat: gpos.lat(), lng: gpos.lng()});
            }

            return polygon;

        } catch (e) {
            return [];
        }
    },

    generatePolygonFromGeojson(geojson) {
        try {
            const geometry = geojson.features[0].geometry;

            let polygons = [];

            if (geometry.type === 'Polygon') {

                polygons = geometry.coordinates.map(linearRing =>
                    linearRing.slice(0, linearRing.length - 1)
                        .map(([lng, lat]) => ({lat, lng}))
                );

            } else if (geometry.type === 'MultiPolygon') {

                polygons = geometry.coordinates.map(polygon =>
                    polygon.map(linearRing =>
                        linearRing.slice(0, linearRing.length - 1)
                            .map(([lng, lat]) => ({lat, lng}))
                    ));

            }

            return {
                geometry: geometry.type,
                polygons
            };

        } catch (e) {
            return [];
        }
    },

    shapeOptions() {
        return {
            raw: {
                fillColor: '#777',
                fillOpacity: 0.1,
                strokeWeight: 2,
                strokeColor: '#999',
                draggable: true,
                editable: true,
                clickable: true
            },
            applied: {
                fillColor: '#e7f1fe',
                fillOpacity: 0.5,
                strokeWeight: 2,
                strokeColor: '#221ecd',
                draggable: false,
                editable: false,
                clickable: false
            }
        }
    },

    markerIcon() {
        return {
            default: {
                url: '/icons/map_marker/default.png',
                scaledSize: {
                    height: 20,
                    width: 20
                },
            },
            hover: {
                url: '/icons/map_marker/hover.png',
                scaledSize: {
                    height: 20,
                    width: 20
                },
            }
        }
    },

    infoWindowsOptions() {
        return {
            content: '',
            pixelOffset: {
                height: -20,
                width: 0
            }
        }
    },
}