import axios from 'axios';

class Geojson {
    constructor(options = {}) {
        this._apiUrl = options.apiUrl || 'https://nominatim.openstreetmap.org/search.php';
        this._apiKey = options.apiKey || '';
    }

    get apiUrl() { return this._apiUrl; }
    get apiKey() { return this._apiKey; }

    set apiUrl(url) { this._apiUrl = url; }
    set apiKey(key) { this._apiKey = key; }

    async createRequestObject(url, params) {
        return axios({url, method: 'GET', params});
    }

    async getFromOpenStreetMap(searchTerm) {
        try {
            let url = this.apiUrl;

            if (this.apiKey) {
                url += "?key=" + encodeURIComponent(this.apiKey);
            }

            const params = {
                q: searchTerm,
                polygon_geojson: 1,
                format: "json"
            };

            const response = await this.createRequestObject(url, params);
            const geoJSONDataChunk = response.data[0];

            return {
                geojson: {
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: geoJSONDataChunk.geojson
                    }]
                },
                latLang: {
                    lat: parseFloat(geoJSONDataChunk.lat),
                    lng: parseFloat(geoJSONDataChunk.lon)
                },
                boundingBox: {
                    south: parseFloat(geoJSONDataChunk.boundingbox[0]),
                    north: parseFloat(geoJSONDataChunk.boundingbox[1]),
                    west: parseFloat(geoJSONDataChunk.boundingbox[2]),
                    east: parseFloat(geoJSONDataChunk.boundingbox[3])
                },
            };

        } catch (e) {
            return null;
        }
    }
}

export default Geojson;