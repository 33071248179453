import moment from "moment";

export default {
    barChartOptions(options = {}) {
        const opts = {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    },
                },
            },
            dataLabels: {
                formatter: function (val) {
                    return val > 0 ? val : ''
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 3,
                    horizontal: true,
                    barHeight: 95
                }
            },
            colors: ['#0D3AFF'],
            legend: {
                show: false
            },
            xaxis: {
                categories: [],
            },
            yaxis: {},
        }

        if (options.colors) {
            opts['colors'] = typeof options.colors === "string" ? [options.colors] : options.colors
        }

        return opts;
    },

    lastXYears(x = 10, reverse) {
        const years = [],
            dateStart = moment().subtract(x, 'y'),
            dateEnd = moment();

        while (dateEnd.diff(dateStart, 'years') >= 0) {
            years.push(dateStart.format('YYYY'));
            dateStart.add(1, 'year');
        }

        if (reverse) {
            return years.sort((a, b) => b - a);
        }

        return years;
    }
}