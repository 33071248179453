import Dh from '@/helpers/date.helper';
import AsideDetails from './../MapAsideDetails';

import CrossSell from '@/services/cross.sell.service';

const service = new CrossSell();

export default {
    name: 'CrossSellMapAside',

    components: {
        AsideDetails
    },

    props: {
        mapViewport: Array,
        mapBoundary: Object,
        selectionCount: {type: Number, default: null},
        selectionResults: {type: Array, default: []},
    },

    data() {
        return {
            scrollTop: true,
            showDropdown: false,
            ranges: [
                {value: 'all_time', text: this.$t('all_time')},
                {value: 'month_to_day', text: this.$t('month_to_day')},
                {value: 'last_month', text: this.$t('last_month')},
                {value: 'year_to_day', text: this.$t('year_to_day')},
                {value: 'last_year', text: this.$t('last_year')},
                {value: 'custom', text: this.$t('custom')},
            ],
            filters: {          // Default filters
                dateRange: 'all_time',
                dateFrom: null,
                dateTo: null
            },
            datePicker: {       // Applied filters
                dateRange: '',
                dateFrom: '',
                dateTo: ''
            },
            infoCategories: ['dealers', 'sellers', /*'makes',*/ 'lenders', 'buyers'],
            selectedCategory: '',

            dealers: this.selectionResults,
            sellers: {loading: false, results: []},
            makes: {loading: false, results: []},
            lenders: {loading: false, results: []},
            buyers: {loading: false, results: []},

            // Right aside
            showRightAside: false,
        }
    },

    computed: {
        selectedRange() {
            if (this.datePicker.dateRange === '' || this.datePicker.dateRange === 'all_time') {
                return this.$t('date');
            } else if (this.datePicker.dateRange === 'custom') {
                return Dh.customFormat(this.datePicker.dateFrom, 'MM/DD/YYYY') +
                    ' to ' + Dh.customFormat(this.datePicker.dateTo, 'MM/DD/YYYY');
            } else {
                return this.$t(this.datePicker.dateRange);
            }
        },

        activeFilter() {
            return this.showDropdown || (this.datePicker.dateFrom.length && this.datePicker.dateTo.length)
        },

        boundary() {
            return {
                boundaryType: this.mapBoundary.type,
                boundaryValue: this.mapBoundary.value,
                boundaryViewport: this.mapViewport,
            }
        },
    },

    mounted() {
        this.getTopsData();
    },

    methods: {
        onScroll(e) {
            this.scrollTop = !(e.target.scrollTop > 0);
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        clearDateRange() {
            this.filters.dateRange = 'all_time';
        },

        saveDateRange() {
            this.showDropdown = false;
            this.datePicker.dateRange = this.filters.dateRange;
            this.datePicker.dateFrom = this.filters.dateFrom ? Dh.customFormat(this.filters.dateFrom) : '';
            this.datePicker.dateTo = this.filters.dateTo ? Dh.customFormat(this.filters.dateTo) : '';
            this.getTopsData();
        },

        openRightAside(category) {
            this.showRightAside = true;
            this.selectedCategory = category;
        },

        closeRightAside() {
            this.showRightAside = false;
            this.selectedCategory = '';
        },

        async countSellers() {
        },

        getTopsData() {
            this.topSellers();
            this.topMakes();
            this.topBanks();
            this.topBuyers();
        },

        async topSellers() {
            this.sellers.loading = true;
            this.sellers.results = [];

            const res = await service.getTopByBoundary(this.boundary, 10, "seller_name", this.datePicker);
            if (res && !res.error) {
                this.sellers.loading = false;
                this.sellers.results = res.data;
            }
        },

        async topMakes() {
            this.makes.loading = true;
            this.makes.results = [];

            const res = await service.getTopByBoundary(this.boundary, 10, "make", this.datePicker);
            if (res && !res.error) {
                this.makes.loading = false;
                this.makes.results = res.data;
            }
        },

        async topBanks() {
            this.lenders.loading = true;
            this.lenders.results = [];

            const res = await service.getTopByBoundary(this.boundary, 10, "lienholder", this.datePicker);
            if (res && !res.error) {
                this.lenders.loading = false;
                this.lenders.results = res.data;
            }
        },

        async topBuyers() {
            this.buyers.loading = true;
            this.buyers.results = [];

            const res = await service.getTopByBoundary(this.boundary, 10, "owner_zip", this.datePicker);
            if (res && !res.error) {
                this.buyers.loading = false;
                this.buyers.results = res.data;
            }
        },
    },

    watch: {
        mapBoundary: {
            deep: true,
            handler() {
                this.getTopsData();
            }
        },

        'filters.dateRange': {
            handler(val) {
                if (val === 'all_time') {
                    this.filters.dateFrom = '';
                    this.filters.dateTo = '';

                } else if (val === 'month_to_day') {
                    this.filters.dateFrom = Dh.startOf(new Date(), 'month');
                    this.filters.dateTo = Dh.endOf(new Date(), 'month');

                } else if (val === 'last_month') {
                    this.filters.dateFrom = Dh.subtract(new Date(), 1, 'month').startOf('month');
                    this.filters.dateTo = Dh.subtract(new Date(), 1, 'month').endOf('month');

                } else if (val === 'year_to_day') {
                    this.filters.dateFrom = Dh.startOf(new Date(), 'year');
                    this.filters.dateTo = Dh.endOf(new Date(), 'month');

                } else if (val === 'last_year') {
                    this.filters.dateFrom = Dh.subtract(new Date(), 1, 'year').startOf('year');
                    this.filters.dateTo = Dh.subtract(new Date(), 1, 'year').endOf('year');

                } else if (val === 'custom') {
                    this.filters.dateFrom = '';
                    this.filters.dateTo = '';
                }
            }
        },

        'filters.dateFrom': {
            handler(dateFrom) {
                if (!dateFrom) {
                    this.filters.dateTo = dateFrom
                } else if (dateFrom > this.filters.dateTo) {
                    this.filters.dateTo = dateFrom
                }
            }
        },

        'filters.dateTo': {
            handler(dateTo) {
                if (!dateTo) {
                    this.filters.dateFrom = dateTo;
                } else if (this.filters.dateFrom > dateTo) {
                    this.filters.dateFrom = dateTo
                }
            }
        }
    },
}