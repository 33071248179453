class PlaceSearch {

    constructor(options) {
        this._defaultType = options.type || 'car_dealer';
        this._defaultRadius = options.radius || 500; // meters
    }

    get defaultType() { return this._defaultType }
    get defaultRadius() { return this._defaultRadius }

    set defaultTypes(type) { this._defaultType = type; }
    set defaultRadius(radius) { this._defaultRadius = radius; }

    createRequestService() {
        return new window.google.maps.places.PlacesService(document.createElement('div'));
    }

    TextSearch(query, callback = null) {
        this.createRequestService().textSearch({ query: query, type: this.defaultType }, callback);
    }
}

export default PlaceSearch;