import helpers from "../helpers";
import Dh from '@/helpers/date.helper';

import CrossSell from '@/services/cross.sell.service';

const service = new CrossSell();

export default {
    name: 'CarMakesChart',

    props: {
        category: String,
        mapBoundary: Object,
        datePicker: Object,
    },

    data() {
        return {
            makesHeaders: [],
            makesSeries: [],
            modelsHeaders: [],
            modelsSeries: [],
            filters: {
                make: '',
                stacked: '',
                years: {
                    from: Dh.subtract(new Date(), 5, 'years').format('YYYY'),
                    to: Dh.customFormat(new Date(), 'YYYY'),
                },
            },
            filtersSelected: {
                make: '',
                stacked: '',
                years: {
                    from: '',
                    to: '',
                },
            },
            showDropdown: {
                years: false,
                stacked: false,
            },
            yearRanges: helpers.lastXYears(10, true),
            perPage: 10,
            currentPage: {
                general: 1,
                models: 1
            },
            loading: {
                makes: false,
                models: false
            }
        }
    },

    computed: {
        chartColors() {
            return this.filtersSelected.stacked ? ['#0D3AFF', '#00C1D4'] : ['#221ECD'];
        },

        makesOptions() {
            const chartOptions = helpers.barChartOptions({colors: this.chartColors});
            chartOptions.xaxis.categories = this.makesHeaders;
            return chartOptions;
        },

        modelsOptions() {
            const chartOptions = helpers.barChartOptions({colors: this.chartColors});
            chartOptions.xaxis.categories = this.modelsHeaders;
            return chartOptions;
        },

        disablePrevBtn() {
            if (this.filtersSelected.make.length > 0) {
                return this.currentPage.models <= 1
            } else {
                return this.currentPage.general <= 1
            }
        },

        disableNextBtn() {
            return this.modelsHeaders.length < this.perPage;
        }
    },

    mounted() {
        this._getMakes();
        this._getModels();
    },

    methods: {
        toggleDropdown(type) {
            for (const key in this.showDropdown) {
                if (type !== key) this.showDropdown[key] = false;
            }

            this.showDropdown[type] = !this.showDropdown[type];
        },

        filterActive(type) {
            if (type === 'years') {
                return this.showDropdown[type] || this.filtersSelected[type].from.length;
            }
            if (type === 'stacked') {
                return this.showDropdown[type] || this.filtersSelected[type].length;
            }
        },

        filterYearSelected() {
            const filter = this.filtersSelected['years'];
            if (!filter.from.length) {
                return this.$t('years');
            } else if (filter.from === filter.to) {
                return filter.from;
            } else {
                return filter.from + '-' + filter.to;
            }
        },

        clearFilter(type) {
            if (type === 'year') {
                this.filters.years.from = '';
                this.filters.years.to = '';
            }
            if (type === 'stacked') {
                this.filtersSelected.stacked = '';
                this._getMakes();
                this._getModels();
            }
            if (type === 'make') {
                this.$refs.makeChart.toggleDataPointSelection(0,
                    this.makesHeaders.indexOf(this.filtersSelected.make)
                );
                this.filtersSelected.make = '';
                this.currentPage.models = 1;
                this._getModels();
            }
        },

        mouseEnterHandler(event) {
            event.path[0].style.cursor = "pointer";
        },

        setStacked(stacked) {
            this.toggleDropdown('stacked');
            this.filtersSelected.stacked = stacked;
            this._getMakes();
            this._getModels();
        },

        setYears() {
            this.toggleDropdown('years');
            this.filtersSelected.years = {...this.filters.years};
            this._getMakes();
            this._getModels();
        },

        setMake(event, chartContext, config) {
            if (config.dataPointIndex > -1) {
                this.filtersSelected.make = this.makesHeaders[config.dataPointIndex];
                this.currentPage.models = 1;
                this._getModels();
            }
        },

        async _getMakes() {
            const skip = this.perPage * (this.currentPage.general - 1),
                boundary = JSON.stringify(this.mapBoundary),
                picker = JSON.stringify(this.datePicker),
                yearRange = JSON.stringify(this.filtersSelected.years),
                stacked = this.filtersSelected.stacked;

            this.loading.makes = true;

            const res = await service.getTopMakes(this.perPage, skip, boundary, picker, yearRange, stacked);
            if (res && !res.error) {
                const chart = this._parseChartData(res.data);
                this.makesHeaders = chart.options;
                this.makesSeries = chart.series;
                this.loading.makes = false;
            }
        },

        async _getModels() {
            const currentPage = this.filtersSelected.make.length > 0 ? this.currentPage.models : this.currentPage.general;
            const skip = this.perPage * (currentPage - 1),
                boundary = JSON.stringify(this.mapBoundary),
                picker = JSON.stringify(this.datePicker),
                yearRange = JSON.stringify(this.filtersSelected.years),
                stacked = this.filtersSelected.stacked,
                make = this.filtersSelected.make;

            this.loading.models = true;

            const res = await service.getTopModels(this.perPage, skip, boundary, picker, yearRange, stacked, make);
            if (res && !res.error) {
                const chart = this._parseChartData(res.data);
                this.modelsHeaders = chart.options;
                this.modelsSeries = chart.series;
                this.loading.models = false;
            }
        },

        _parseChartData(data) {
            const options = [],
                series1 = {name: this.$t('sales'), data: []},
                series2 = {name: this.$t('sales'), data: []};

            // Fill values
            for (let el of data) {
                options.push(el._id)

                if (!this.filtersSelected.stacked) {
                    series1.data.push(el.count)
                } else if (this.filtersSelected.stacked === 'car_truck') {
                    series1.name = this.$t('cars')
                    series1.data.push(el.Car)
                    series2.name = this.$t('trucks')
                    series2.data.push(el.Truck)
                } else if (this.filtersSelected.stacked === 'new_used') {
                    series1.name = this.$t('new')
                    series1.data.push(el.New)
                    series2.name = this.$t('used')
                    series2.data.push(el.Used)
                } else if (this.filtersSelected.stacked === 'fleet_retail') {
                    series1.name = this.$t('retail')
                    series1.data.push(el.Retail)
                    series2.name = this.$t('fleet')
                    series2.data.push(el.Fleet)
                }
            }

            // Fill missing values
            if (this.perPage > options.length) {
                const fillTotal = this.perPage - options.length;

                for (let i = 0; i < fillTotal; i++) {
                    options.push('');
                    series1.data.push(0);
                }
            }

            const series = [series1];
            if (series2.data.length) {
                series.push(series2)
            }

            return {options, series: data.length ? series : []}
        },

        prev() {
            if (this.disablePrevBtn) {
                return;
            }

            if (this.filtersSelected.make.length > 0) { // Paginate models
                this.currentPage.models -= 1;
                this._getModels();
            } else { // Paginate makes and models
                this.currentPage.general -= 1;
                this._getMakes();
                this._getModels();
            }
        },

        first() {
            if (this.disablePrevBtn) {
                return;
            }

            if (this.filtersSelected.make.length > 0) { // Paginate models
                this.currentPage.models = 1;
                this._getModels();
            } else { // Paginate makes and models
                this.currentPage.general = 1;
                this._getMakes();
                this._getModels();
            }
        },

        next() {
            if (this.disableNextBtn) {
                return;
            }

            if (this.filtersSelected.make.length > 0) { // Paginate models
                this.currentPage.models += 1;
                this._getModels();
            } else { // Paginate makes and models
                this.currentPage.general += 1;
                this._getMakes();
                this._getModels();
            }
        },
    },

    watch: {
        'filters.years.from': {
            handler(yearsFrom) {
                if (!yearsFrom) {
                    this.filters.years.to = yearsFrom
                } else if (yearsFrom > this.filters.years.to) {
                    this.filters.years.to = yearsFrom
                }
            }
        },

        'filters.years.to': {
            handler(yearsTo) {
                if (!yearsTo) {
                    this.filters.years.from = yearsTo;
                } else if (this.filters.years.from > yearsTo) {
                    this.filters.years.from = yearsTo
                }
            }
        },

        datePicker: {
            deep: true,
            handler() {
                this._getMakes();
                this._getModels();
            }
        }
    },

    filters: {}
}